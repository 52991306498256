import { createGlobalStyle } from 'styled-components'
import { Container, SubSectionTitle, Text, Title } from './TermsOfService.style'

const PrivacyGlobalStyle = createGlobalStyle`
  body {
    background: #fdfdfd;
  }
`

function TermsOfService() {
  return (
    <>
      <PrivacyGlobalStyle />
      <Container>
        <Title data-test-id="page-title">TERMS OF SERVICE</Title>
        <br />
        <Text>
          <b>
            <i>Last Revised on August 11, 2022</i>
          </b>
        </Text>
        <br />
        <Text>
          This Privacy Policy for ErthAI. (
          <b>"Company", "we", "us" "our"</b>) describes how we collect, use and
          disclose information about users of the Company's website (erth.xyz),
          map, Land Rush and other game experiences, applications, services,
          tools and features (collectively, the <b>"Services"</b>). For the
          purposes of this Privacy Policy, <b>"you"</b> and <b>"your"</b> means
          you as the user of the Services.
        </Text>
        <br />
        <Text>
          <b>
            Please read this Privacy Policy carefully. By using, accessing or
            downloading any of the services, you agree to the collection, use
            and disclosure of your information as described in this Privacy
            Policy. If you do not agree to this Privacy Policy, please do not
            use, access or download any of the Services.
          </b>
        </Text>
        <br />
        <SubSectionTitle>1. UPDATING THIS PRIVACY POLICY</SubSectionTitle>
        <Text>
          We may modify this Privacy Policy from time to time in which case we
          will update the "Last Revised" date at the top of this Privacy Policy.
          If we make material changes to the way in which we use information we
          collect, we will use reasonable efforts to notify you and take such
          other steps as required by applicable law.{' '}
          <b>
            If you do not agree to any updates to this Privacy Policy, please do
            not access or continue to use the Services.
          </b>
          <br />
        </Text>
        <SubSectionTitle>
          2. COMPANY'S COLLECTION AND USE OF INFORMATION
        </SubSectionTitle>
        <Text>
          When you access or use the Services, we may collect certain categories
          of information about you from a variety of sources.
        </Text>
        <br />
        <Text>
          Some features of the Services may require you to directly enter
          certain information about yourself. You may elect not to provide this
          information, but doing so may prevent you from using or accessing
          these features. Information that you directly submit through our
          Services may include:
        </Text>
        <br />
        <ul>
          <li>
            The following contact information: email address and Discord Tag. We
            collect your email address and Discord Tag in order to communicate
            with you through the Services and through third party platforms,
            such as Discord.
          </li>
          <li>
            The following account information: username and password. We collect
            account information to maintain and secure your account with us. If
            you choose to use the Services and register an account, you are
            responsible for keeping your account credentials safe. We highly
            recommend that you do not share your username, password, or other
            access details with anyone else. If you believe your account has
            been compromised, please contact us immediately.
          </li>
          <li>
            Any other information you choose to include in communications with
            us, for example, when sending a message through the Services.
          </li>
        </ul>
        <Text>
          We also automatically collect certain information about your
          interaction with the Services (<b>"Usage Data"</b>). To do this, we
          may use cookies, web beacons/clear gifs and other geolocation tracking
          technologies (<b>"Tracking Technologies"</b>). Usage Data includes:
        </Text>
        <ul>
          <li>IP address</li>
          <li>Geolocation</li>
          <li>Device type, such as your phone, computer, or tablet</li>
          <li>Browser type</li>
          <li>
            Date and time stamps, such as the date and time you first accessed
            the Services
          </li>
          <li>Operating system</li>
          <li>Log data</li>
          <li>
            Other information regarding your interaction with the Services, such
            as clickstream data and ad impressions
          </li>
        </ul>
        <Text>
          We use the information we collect automatically to tailor features and
          content to you, to market to you, to improve the Services and to run
          analytics and better understand user interaction with the Services.
        </Text>
        <br />
        <Text>
          In addition to the foregoing, we may use any of the above information
          to comply with any applicable legal obligations, to enforce any
          applicable terms of service, and to protect or defend the Services,
          our rights, and the rights of our users or others.
        </Text>
        <SubSectionTitle>
          3. HOW THE COMPANY SHARES YOUR INFORMATION
        </SubSectionTitle>
        <Text>
          The Company may share your information with third parties for
          legitimate purposes subject to this Privacy Policy in the following
          circumstances:
        </Text>
        <ul>
          <li>
            With vendors or other service providers, such as for
            <ul>
              <li>Error-logging (e.g., Sentry)</li>
              <li>Data analytics (e.g., Google Analytics)</li>
              <li>
                Know-your-customer and anti-money laundering purposes (e.g.,
                Chainalysis)
              </li>
              <li>Security services (e.g., Cloudflare)</li>
              <li>Cloud storage (e.g., Amazon Web Services)</li>
            </ul>
          </li>
          <li>With our affiliates or otherwise within our corporate group</li>
          <li>
            To comply with applicable law or any obligations thereunder,
            including cooperation with law enforcement, judicial orders, and
            regulatory inquiries
          </li>
          <li>
            In connection with an asset sale, merger, bankruptcy, or other
            business transaction
          </li>
          <li>To enforce any applicable terms of service</li>
          <li>
            To ensure the safety and security of the Company and/or its users
          </li>
          <li>
            When you request us to share certain information with third parties,
            such as through your use of social media widgets or login
            integrations
          </li>
          <li>
            With professional advisors, such as auditors, law firms, or
            accounting firms
          </li>
        </ul>
        <br />
        <SubSectionTitle>
          4. COOKIES AND OTHER TRACKING TECHNOLOGIES
        </SubSectionTitle>
        <Text>
          <i>Do Not Track Signals</i>
        </Text>
        <br />
        <Text>
          Your browser settings may also allow you to transmit a "Do Not Track"
          signal when you visit various websites. Like many websites, our
          website is not designed to respond to "Do Not Track" signals received
          from browsers. To learn more about "Do Not Track" signals, you can
          visit <a href="http://www.allaboutdnt.com/" target="_blank" rel="noreferrer">http://www.allaboutdnt.com/</a>
          .
        </Text>
        <br />
        <Text>
          <i>Cookies and Other Tracking Technologies</i>
        </Text>
        <br />
        <Text>
          Most browsers accept cookies automatically, but you may be able to
          control the way in which your devices permit the use of Tracking
          Technologies. If you so choose, you may block or delete our cookies
          from your browser; however, blocking or deleting cookies may cause
          some of the Services, including any portal features and general
          functionality, to work incorrectly.
        </Text>
        <br />
        <Text>
          To opt out of tracking by Google Analytics, click <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">here</a>
          .
        </Text>
        <br />
        <Text>
          If you have questions regarding the specific information about you
          that we process or retain, as well as your choices regarding our
          collection and use practices, please contact us using the information
          listed below.
        </Text>
        <SubSectionTitle>5. USER GENERATED CONTENT</SubSectionTitle>
        <Text>
          Certain features of the Services may allow you to upload content
          (i.e., photographs and descriptions of your land parcels) (
          <b>"User-Generated Content"</b> or <b>"UGC"</b>). We or others may
          store, display, reproduce, publish, or otherwise use UGC, and may or
          may not attribute it to you. Others may also have access to UGC and
          may have the ability to share it with third parties. If you choose to
          submit UGC to any public area of the Services, your UGC will be
          considered "public" and will be accessible by anyone, including the
          Company.
        </Text>
        <br />
        <Text>
          Please note that we do not control who will have access to the
          information that you choose to make available to others, and we cannot
          ensure that parties who have access to such information will respect
          your privacy or keep it secure. We are not responsible for the privacy
          or security of any information that you make publicly available on the
          features permitting creation of UGC or what others do with information
          you share with them on such platforms. We are not responsible for the
          accuracy, use or misuse of any UGC that you disclose or receive from
          third parties through the forums or email lists.
        </Text>
        <SubSectionTitle>
          6. SOCIAL NETWORKS AND OTHER THIRD PARTY WEBSITES AND LINKS (INCLUDING
          WALLET EXTENSIONS)
        </SubSectionTitle>
        <Text>
          We may provide links to websites or other online platforms, including
          third-party social networking platforms, such as Twitter, Discord, or
          Medium, operated by third parties (such platforms are{' '}
          <b>"Social Networks"</b>). If you follow links to sites not affiliated
          or controlled by us, you should review their privacy and security
          policies and other terms and conditions. We do not guarantee and are
          not responsible for the privacy or security of these sites, including
          the accuracy, completeness, or reliability of information found on
          these sites. Information you provide on public or semi-public venues,
          including information you share or post on Social Networks, may also
          be accessible or viewable by other users of the Services and/or users
          of those third-party online platforms without limitation as to its use
          by us or by a third party. Our inclusion of such links does not, by
          itself, imply any endorsement of the content on such platforms or of
          their owners or operators, except as disclosed on the Services. Any
          information submitted by you directly to these third parties, by
          interacting with Social Networks or through the use of third-party
          applications to conduct transactions on the Platform is subject to that
          third party's privacy policy. We expressly disclaim any and all
          liability for the actions of third parties, including but without
          limitation to actions relating to the use and/or disclosure of
          personal information by third parties, including Social Networks, or
          arising from the use of third-party applications.
        </Text>
        <SubSectionTitle>7. CHILDREN'S PRIVACY</SubSectionTitle>
        <Text>
          Children under the age of 18 are not permitted to use the Services,
          and we do not seek or knowingly collect any personal information about
          children under 18 years of age. If you are the parent or guardian of a
          child under 18 years of age who may have provided us with their
          personal information, please contact us using the information in
          Section 11.
        </Text>
        <SubSectionTitle>8. DATA SECURITY</SubSectionTitle>
        <Text>
          Please note that any information you send to us electronically, while
          using the Services or otherwise interacting with us, may not be secure
          when it is transmitted to us. We recommend that you do not use
          unsecure channels to communicate sensitive or confidential information
          to us. Please be aware though that, despite our best efforts, no
          security measures are perfect or impenetrable, and we cannot guarantee
          "perfect security." Any information you transmit to us you do so at
          your own risk.
        </Text>
        <SubSectionTitle>9. CALIFORNIA PRIVACY RIGHTS</SubSectionTitle>
        <Text>
          <i>Sharing for Direct Marketing Purposes</i>
        </Text>
        <br />
        <Text>
          We do not share personal information as defined by California Civil
          Code Section 1798.83 ("Shine The Light Law") with third parties for
          their direct marketing purposes.{' '}
        </Text>
        <br />
        <Text>
          <i>Not Intended for Users Under 18</i>
        </Text>
        <br />
        <Text>
          Children under the age of 18 are not permitted to use the Services. If
          the Company learns that someone under the age of eighteen (18)
          violates this rule and improperly registers to use the Services, the
          Company reserves the right to take any and all corrective action,
          including by making reasonable good faith efforts to block such
          person's account or remove the post from prospective public view or
          anonymize it so the minor cannot be individually identified. In
          addition, any California resident under the age of 18 who violates
          this rule and improperly registers to use the Services and posts
          content or information on the Services may self report such violation
          and request that such information be removed from the Services by
          contacting us at the e-mail or address set forth in the "How to
          Contact Us" section below. Such request must state that they
          personally posted such content or information and detail where the
          content or information is posted. We will make reasonable good faith
          efforts to remove the user's account or post from prospective public
          view or anonymize it so the minor cannot be individually identified.
          This removal process cannot ensure complete or comprehensive removal.
          For instance, third-parties may have republished the post and archived
          copies of it may be stored by search engines and others that we do not
          control.
        </Text>
        <SubSectionTitle>10. INTERNATIONAL USERS</SubSectionTitle>
        <Text>
          We are based in the United States. If you access the Services from
          outside the United States, we want to remind you that our handling of
          information about you will be subject to United States data privacy
          laws.{' '}
        </Text>
        <br />
        <SubSectionTitle>11. HOW TO CONTACT US</SubSectionTitle>
        <Text>
          Should you have any questions about our privacy practices or this
          Privacy Policy, please email us at privacy@erth.xyz.
        </Text>
      </Container>
    </>
  )
}

export default TermsOfService
