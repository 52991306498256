import { Footer } from '../Footer/Footer';
import {
  Container,
  Content,
  Desc,
  ImageWrapper,
  Link,
  Title,
  Wrapper,
} from './ContactUs.style';
import useWindowDimensions from '../../hooks/useWindowDimension';

export const ContactUs = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 1024;
  const imageUrl = isMobile
    ? '/images/prelaunch/game-mobile.png'
    : '/images/prelaunch/game.png';

  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <img src={imageUrl} alt="contact us" />
        </ImageWrapper>
        <Content>
          <Title>are you a discord mod?</Title>
          <Desc>
            We’re partnering with select Discord servers to be part of our early
            access beta.
          </Desc>
          <Link href="mailto:socials@pokerparty.io">
            Contact us today {'>'}
          </Link>
        </Content>
      </Wrapper>
      <Footer />
    </Container>
  );
};
