import styled from 'styled-components';
import { breakpoints } from '../../styles/constants';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  background-image: url('/images/backgrounds/pattern.png');
  background-size: cover;
  background-position: top center;
  padding: 100px 20px;

  .logo {
    height: 380px;
  }

  ${breakpoints.tabletL} {
    padding: 80px 20px;
    .logo {
      height: 300px;
    }
  }

  ${breakpoints.mobileL} {
    padding: 40px 20px 60px;
    .logo {
      height: 200px;
    }
  }

  > button {
    cursor: not-allowed;
  }
`;

export const Title = styled.h2`
  font-size: 50px;
  font-weight: 700;
  text-align: center;

  ${breakpoints.tabletL} {
    font-size: 42px;
  }

  ${breakpoints.mobileL} {
    font-size: 32px;
  }
`;
