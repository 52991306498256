import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/constants';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 20px;
  gap: 60px;
  background: ${colors.background.secondary};
`;

export const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 50px;
  border-radius: 20px;
  border: 3px solid ${colors.border.primary};
  ${breakpoints.tabletL} {
    flex-direction: column;
  }

  ${breakpoints.tablet} {
     padding: 30px;
  }

  ${breakpoints.mobileL} {
    padding: 0;
    border: none;
  }
}
`;

export const ImageWrapper = styled.div`
  max-width: 322px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }

  ${breakpoints.tabletL} {
    max-width: 100%;
    width: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${breakpoints.tabletL} {
    align-items: center;
  }
  ${breakpoints.mobileL} {
    gap: 20px;
  }
`;

export const Title = styled.h3`
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;

  ${breakpoints.tabletL} {
    text-align: center;
  }

  ${breakpoints.mobileL} {
    font-size: 24px;
  }
`;

export const Desc = styled.p`
  margin: 0;
  font-size: 30px;
  font-weight: 700;

  ${breakpoints.tabletL} {
    text-align: center;
  }

  ${breakpoints.mobileL} {
    font-size: 16px;
  }
`;

export const Link = styled.a`
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  text-decoration: underline;

  ${breakpoints.mobileL} {
    font-size: 20px;
  }
`;
