import React from 'react';
import styled from 'styled-components';
import { Hero } from './components/Hero/Hero';
import { ComingSoon } from './components/ComingSoon/ComingSoon';
import { ContactUs } from './components/ContactUs/ContactUs';
import TermsOfService from './components/TermsOfService/TermsOfService';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

function App() {
  // Very simple way to determine if we should show Terms page
  const isTermsPage = window.location.pathname === '/terms';

  return (
    <Container className="App">
      {isTermsPage ? (
        <TermsOfService />
      ) : (
        <>
          <Hero />
          <ComingSoon />
          <ContactUs />
        </>
      )}
    </Container>
  );
}

export default App;
