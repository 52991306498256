import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/constants';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px 20px;
  gap: 60px;
  background: ${colors.background.primary};
`;

export const Title = styled.h2`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  ${breakpoints.tabletL} {
    font-size: 42px;
  }
  ${breakpoints.mobileL} {
    font-size: 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 70px;

  > {
    img {
      height: auto;
      max-width: calc(100% - (514px + 71px));
    }
  }

  ${breakpoints.tabletL} {
    flex-direction: column;
    align-items: center;
    gap: 60px;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 815px;
  width: calc(100% - (514px + 71px));
  img {
    width: 100%;
    height: auto;
  }

  ${breakpoints.tabletL} {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 514px;
  width: 100%;
  img {
    height: 115px;
  }

  ${breakpoints.mobileL} {
    flex-direction: column;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${breakpoints.mobileL} {
    gap: 20px;
  }
`;

export const ContentTitle = styled.p`
  font-size: 30px;
  font-weight: 900;
  text-align: left;
  margin: 0;

  ${breakpoints.mobileL} {
    text-align: center;
    font-size: 24px;
  }
`;

export const ContentDescription = styled.p`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0;

  ${breakpoints.mobileL} {
    text-align: center;
  }
`;
