import { Container, Link, Separator, Wrapper } from './Footer.style';

export const Footer = () => {
  return (
    <Container>
      <Link onClick={() => window.location.pathname = '/terms'} style={{ cursor: 'pointer' }}>TERMS</Link>
      <Separator />
      <Wrapper>
        <Link href="https://www.tiktok.com/@pokerpartyio">
          <img src="/icons/social/tiktok.svg" />
        </Link>
        <Link href="https://x.com/pokerpartyio">
          <img src="/icons/social/x.svg" />
        </Link>
        <Link href="https://www.twitch.tv/pokerpartyio">
          <img src="/icons/social/twitch.svg" />
        </Link>
        <Link href="https://www.youtube.com/@pokerpartyio">
          <img src="/icons/social/yt.svg" />
        </Link>
      </Wrapper>
    </Container>
  );
};
