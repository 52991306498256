export const colors = {
  button: {
    primary: {
      background: '#22BC8B',
      color: '#FFFFFF',
    },
  },
  text: {
    primary: '#FFFFFF',
  },
  background: {
    primary: '#5632AD',
    secondary: '#372266',
  },
  border: {
    primary: '#5632AD',
  },
};

export const breakpoints = {
  mobile: '@media only screen and (max-width: 375px)',
  mobileL: '@media only screen and (max-width: 567px)',
  tablet: '@media only screen and (max-width: 767px)',
  tabletL: '@media only screen and (max-width: 1023px)',
  tabletXL: '@media only screen and (max-width: 1144px)',
  laptop: '@media only screen and (max-width: 1279px)',
  laptopL: '@media only screen and (max-width: 1365px)',
  desktopS: '@media only screen and (max-width: 1539px)',
  desktop: '@media only screen and (max-width: 1919px)',
};
