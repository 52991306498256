import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/constants';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  gap: 40px;

  ${breakpoints.tabletL} {
    justify-content: center;
  }

  ${breakpoints.mobileL} {
    flex-direction: column;
    gap: 20px;
  }
  > a {
    cursor: not-allowed;
  }
`;

export const Wrapper = styled.div`
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  ${breakpoints.mobileL} {
    padding-top: 6px;
  }

  ${breakpoints.mobileL} {
    justify-content: space-around;
    gap: 20px;
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 78px;
  background: ${colors.border.primary};

  ${breakpoints.mobileL} {
    width: 100%;
    height: 3px;
  }
`;

export const Link = styled.a`
  font-family: Rajdhani;
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  img {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  ${breakpoints.mobileL} {
    img {
      transform: scale(0.7);
    }
    &:hover {
      img {
        transform: scale(0.8);
      }
    }
  }
`;
