import { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { StyledButton } from '../ui/StyledButton.style';
import { Container, Title } from './Hero.style';

export const Hero = () => {
  const { width } = useWindowDimensions();
  const [isHovered, setIsHovered] = useState(false);
  const isTablet = width < 1024 && width > 567;
  const isMobile = width < 568;

  const buttonWidth = isMobile ? '100%' : isTablet ? '80%' : '480px';
  const buttonHeight = isMobile ? '100px' : isTablet ? '115px' : '130px';
  const buttonFontSize = isMobile ? '28px' : isTablet ? '34px' : '38px';
  const buttonImageWidth = isMobile ? '43px' : isTablet ? '55px' : '65px';

  const handleDiscordClick = () => {
    window.open('https://discord.com/api/oauth2/authorize?client_id=1296230469490180248&permissions=0&scope=bot%20applications.commands', '_blank', 'noopener,noreferrer');
  };

  return (
    <Container>
      <img src={'/images/prelaunch/logo.png'} alt="logo" className="logo" />
      <Title>JOIN THE ULTIMATE POKER GAME ON DISCORD!</Title>
      <StyledButton
        width={buttonWidth}
        height={buttonHeight}
        $fontSize={buttonFontSize}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleDiscordClick}
        style={{ cursor: 'pointer' }}
      >
        {!isHovered ? (
          <>
            <img
              src={'/icons/social/discord.svg'}
              alt="discord"
              width={buttonImageWidth}
            />
            get early access
          </>
        ) : (
          <>install now</>
        )}
      </StyledButton>
    </Container>
  );
};
