import {
  Container,
  Content,
  ContentBody,
  ContentDescription,
  ContentItem,
  ContentTitle,
  ImageWrapper,
  Title,
  Wrapper,
} from './ComingSoon.style';

export const ComingSoon = () => {
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <img
            src={'/images/prelaunch/game-preview.png'}
            alt="coming soon"
            width={815}
            height={470}
          />
        </ImageWrapper>

        <Content>
          <ContentItem>
            <img src={'/images/prelaunch/cards.png'} alt="coming soon" />
            <ContentBody>
              <ContentTitle>MADE FOR DISCORD</ContentTitle>
              <ContentDescription>
                Jump right into a game on Discord. Trash talk all you want over
                voice!
              </ContentDescription>
            </ContentBody>
          </ContentItem>
          <ContentItem>
            <img src={'/images/prelaunch/coin.png'} alt="coming soon" />
            <ContentBody>
              <ContentTitle>ACTION ALL THE TIME</ContentTitle>
              <ContentDescription>
                Play against real players 24/7. You never know who might sit
                down next!
              </ContentDescription>
            </ContentBody>
          </ContentItem>
          <ContentItem>
            <img src={'/images/prelaunch/token.png'} alt="coming soon" />
            <ContentBody>
              <ContentTitle>YOUR TABLE, YOUR RULES</ContentTitle>
              <ContentDescription>
                Host your own tables and rules for your Discord community.
              </ContentDescription>
            </ContentBody>
          </ContentItem>
        </Content>
      </Wrapper>
    </Container>
  );
};
