import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const StyledButton = styled.button<{
  width?: string;
  height?: string;
  $fontSize?: string;
}>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background: ${colors.button.primary.background};
  color: ${colors.button.primary.color};
  font-size: ${(props) => props.$fontSize || '38px'};
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }
`;
