import styled from 'styled-components';
import { breakpoints } from '../../styles/constants';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh; // This ensures it fills the full viewport height
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  background-image: url('/images/backgrounds/pattern.png');
  background-size: cover;
  background-position: top center;
  padding: 100px 20px;
  margin-left: calc(-50vw + 50%); // This helps center while maintaining full width
  margin-right: calc(-50vw + 50%);

  & > * {  // This centers the content within the full-width container
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  ${breakpoints.tabletL} {
    padding: 80px 20px;
  }

  ${breakpoints.mobileL} {
    padding: 40px 20px 60px;
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;

  ${breakpoints.tabletL} {
    font-size: 42px;
  }

  ${breakpoints.mobileL} {
    font-size: 32px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;

  ${breakpoints.tabletL} {
    font-size: 28px;
  }

  ${breakpoints.mobileL} {
    font-size: 24px;
  }
`;

export const SubSectionTitle = styled(SubTitle)`
  // Inherits all styles from SubTitle
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 1.6;
  
  ${breakpoints.tabletL} {
    font-size: 16px;
  }

  ${breakpoints.mobileL} {
    font-size: 14px;
  }

  ul {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 8px;
  }
`;
